import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";

import * as defaultStyles from "./HtmlModule.module.css";

HtmlModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.shape({
    modHtmlOptions: PropTypes.shape({
      htmlContent: PropTypes.string,
    }),
  }),
};

export default function HtmlModule({
  styles = defaultStyles,
  module: { modHtmlOptions: { htmlContent } = {} },
  ...restProps
}) {
  const componentRef = useRef(null);
  let { width: componentWidth } = useComponentSize(componentRef);
  const isCompact = componentWidth <= 340;

  useEffect(() => {
    const scripts = componentRef.current.querySelectorAll("script");
    scripts.forEach((script) => {
      const newScript = document.createElement("script");
      newScript.text = script.innerText;
      document.body.appendChild(newScript);
    });
  }, [htmlContent]);

  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={clsx(styles.component, isCompact && styles.isCompact)}
      {...restProps}
    >
      <div ref={componentRef}>
        {htmlContent && (
          <div
            className={styles.htmlContent}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        )}
      </div>
    </ModuleWrapper>
  );
}
